html {
	scroll-behavior: smooth;
}

body {
  font-family: 'bai jamjuree', sans-serif;
  margin: 0;
  padding: 0;
  background-color: white;
  color: white;
}

header,
footer {
  background-color: black;
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
}

a {
  color: #59c7f9;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  width: 100%;
  height: auto;
}

main {
  padding: 20px;
  text-align: center;
}

button {
  background-color: #ff0000;
  border: none;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 18px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

button:hover {
  background-color: #cc0000;
}

.button {
  letter-spacing: 1px;
  width: auto;
  display: inline-block;
  margin-top: 20px;
}

p {
  color: #cec7c7;
}

@media (max-width: 900px) {

  main {
    padding: 10px;
  }

  button {
    padding: 12px 24px;
    font-size: 0.9rem;
  }

}

.swiper-button-prev,
.swiper-button-next {
  color: white !important;
}