footer {
    display: flex;
    justify-content: center;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.5);
}

.item2 p {
    color: 'white';
    font-size: 1.7rem;
}

.item3 p,
.item3 a {
    color: #8b8a8a;
    font-size: 1.4rem;
}

.item4 input,
.item4 textarea,
.item4 {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 5px;
    margin: 2px;
}

.item4 button {
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.item4 button:hover {
    background-color: #555;
}

.grid-container {
    display: grid;
    width: 70%;
    grid-template-columns: 50% 50%;
    gap: 10px;
    padding: 10px;
}

.grid-container>div {
    border: 1px solid black;
    font-size: 30px;
}

input[type="email"],
textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    background-color: #222;
    color: white;
}

@media (max-width: 900px) {
    .item2 p {
        color: 'white';
        font-size: 1.1rem !important;
    }

    .item3 p,
    .item3 a {
        color: #8b8a8a;
        font-size: 0.8rem !important;
    }

    .grid-container {
        display: grid;
        width: 100%;
        grid-template-columns: 50% 50%;
        gap: 10px;
        padding: 10px;
    }

    .grid-container>div {
        border: 1px solid black;
        font-size: 5px;
    }
}