.cards-box {
    width: 65%;
    display: inline-block;
}

.card {
    display: flex;
    align-items: center;
    padding-top: 20px;
}

.card img {
    width: 30%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.card-content {
    flex: 1;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 15px;
    margin-right: 20px;
    margin-left: 20px;
}

.card-title {
    color: #666;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.card-text {
    font-size: 1.4rem;
    color: #666;
    text-align: justify !important;
}

.services-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.service-item {
    margin: 20px;
    padding: 10px;
    background-color: #222;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
    width: 300px;
    text-align: center;
    font-size: 18px;
}

@media (max-width: 900px) {
    .cards-box {
        width: 100%;
    }

    .card img {
        width: 40% !important;
    }

    .card-content {
        flex: 1;
        margin: 0 20px;
    }

    .card-title {
        color: #666;
        font-size: 0.8rem;
        font-weight: bold;
        margin-bottom: 10px;
    }

    .card-text {
        font-size: 0.7rem;
        color: #666;
        text-align: justify !important;
    }
}